import Nanny from "nanny-state"
import Mustache from 'mustache'

export default class ModuleLoader extends HTMLElement {

  constructor() {
    super()
    this.initialized = false
  }
  connectedCallback() {
    if (!this.initialized) {
      this.init()
    }
  }
  async init() {
    console.log('module loader init')
    const response = await fetch(this.getAttribute('url'), {
      method: this.getAttribute('method'),
      headers: {
        "Content-Type": "application/json",
      },
      // body: `{"name": "${this.getAttribute('module')}"}`
      body: JSON.stringify({name: this.getAttribute('module')})
    })
    let data = await response.json()
    var output = await Mustache.render(this.querySelector('script').innerHTML, 
    {
        data: data.Data,
        FormatDate: function() {                          
            return (rawDate, render) => {
                return (new Date(htmlDecode(render(rawDate)).trim())).toDateString();
            }
        }
    })
    this.innerHTML = output
  }
}