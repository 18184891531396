import Nanny from "nanny-state"

export default class SocialButton extends HTMLElement {

  constructor() {
    super()
    this.initialized = false
  }

  connectedCallback() {
    if (!this.initialized) {
      this.init()
    }
  }

  async init() {
    console.log('social button')
    this.hostname = window.location.hostname 
    this.setupView()
    // this.setupListener()
  }

  setupView() {
    
    // const fb = (state, props) => {
    //   return state.HTML
    //   `<svg width="22" height="23" viewBox="0 0 22 23" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.9453C22 5.87018 17.0751 0.945312 11 0.945312C4.92486 0.945312 0 5.87018 0 11.9453C0 17.4356 4.02252 21.9865 9.28125 22.8117V15.125H6.48828V11.9453H9.28125V9.52188C9.28125 6.765 10.9235 5.24219 13.4361 5.24219C14.6397 5.24219 15.8984 5.45703 15.8984 5.45703V8.16406H14.5114C13.145 8.16406 12.7188 9.01205 12.7188 9.88195V11.9453H15.7695L15.2818 15.125H12.7188V22.8117C17.9775 21.9865 22 17.4359 22 11.9453Z"></path></svg>`
    // }
    const innerHTML = this.innerHTML
    const platformHTML = (state, props) => {
      const platform = this.getAttribute('platform')
      switch (platform) {
        case 'fb':
          return state.HTML
            `<svg width="22" height="23" viewBox="0 0 22 23" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.9453C22 5.87018 17.0751 0.945312 11 0.945312C4.92486 0.945312 0 5.87018 0 11.9453C0 17.4356 4.02252 21.9865 9.28125 22.8117V15.125H6.48828V11.9453H9.28125V9.52188C9.28125 6.765 10.9235 5.24219 13.4361 5.24219C14.6397 5.24219 15.8984 5.45703 15.8984 5.45703V8.16406H14.5114C13.145 8.16406 12.7188 9.01205 12.7188 9.88195V11.9453H15.7695L15.2818 15.125H12.7188V22.8117C17.9775 21.9865 22 17.4359 22 11.9453Z"></path></svg>`
            break
        case 'tw':
          return state.HTML
            `<svg width="21" height="21" viewBox="0 0 1200 1227" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"></path></svg>`
            break
        default:
          return ''
      }
    }

    const View = state => state.HTML`
      <div class="hover:text-sky-400 hover:cursor-pointer" onclick=${this.openDialog.bind(this)}>${platformHTML(state)}</div>
      <!-- Main modal -->
      <div tabindex="-1" onclick=${this.clickMask.bind(this)} aria-hidden="true" class=${`${state.ui.close?'hidden':''} flex fixed z-10 top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-opacity-70 bg-black box-border`}>
          <div class="relative self-center h-auto w-full max-w-2xl mx-auto p-4 bg-white">
              <!-- Modal content -->
              <div class="dialog relative bg-white rounded-lg h-full box-border flex flex-col">
                  <!-- Modal header -->
                  <div class="flex items-start justify-between p-4rounded-t">
                      <button type="button" onclick=${this.closeDialog.bind(this)} class="text-black bg-transparent border border-solid border-black hover:cursor-pointer hover:text-white hover:bg-black text-sm w-4 h-4 box-content p-2 ml-auto inline-flex justify-center items-center" data-modal-hide="defaultModal">
                          <svg class="w-5 h-5" aria-hidden="true" fill="none" viewBox="0 0 14 14">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                          </svg>
                          <span class="sr-only">Close modal</span>
                      </button>
                  </div>
                  <!-- Modal body -->
                  <div class="flex flex-col">
                    <span class="p-4">You are about to visit a website outside of medicinenet. Please familiarize yourself with this other website's Privacy Policy as it differs from ours.</span>
                    <div class="flex p-2"><button onclick=${this.openUrl.bind(this)} class="m-auto px-14 py-4 border-0 uppercase bg-sky-400 text-white hover:bg-black hover:text-white hover:cursor-pointer">continue</button></div>
                  </div>
              </div>
          </div>
      </div>
    `
    this.State = {
      View,
      Element: this,
      ui: {close: 'hidden'},
      data: {}
    }
    this.Update = Nanny(this.State)
  }
  openDialog() {
    this.Update(state => {
      state.ui.close = ''
      return state
    })
  }
  closeDialog() {
    this.Update(state => {
      state.ui.close = 'close'
      return state
    })
  }
  openUrl() {
    this.closeDialog()
    let url = this.getAttribute('url')
    window.open(url, '_blank')
  }
  clickMask(e) {
    const dialog = this.querySelector('.dialog')
    if (!dialog.contains(e.target)) {
      this.closeDialog()
    }
  }
}