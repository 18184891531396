import Nanny from "nanny-state"

/**
 * Wrapper element for hyperlink element that links to third-party domain
 */
export default class ExternalLink extends HTMLElement {

  constructor() {
    super()
    this.initialized = false
  }

  connectedCallback() {
    if (!this.initialized) {
      this.init()
    }
  }

  async init() {
    this.hostname = window.location.hostname 
    this.setupView()
    this.setupListener()
  }

  setupView() {
    let targetEl = this.querySelector('a')
    let container = document.createElement('div')
    this.appendChild(container)
    const View = state => state.HTML`
      <!-- Main modal -->
      <div tabindex="-1" onclick=${this.clickMask.bind(this)} aria-hidden="true" class=${`${state.ui.close?'hidden':''} flex fixed z-10 top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-opacity-70 bg-black box-border`}>
          <div class="relative self-center h-auto w-full max-w-2xl mx-auto p-4 bg-white">
              <!-- Modal content -->
              <div class="dialog relative bg-white rounded-lg h-full box-border flex flex-col">
                  <!-- Modal header -->
                  <div class="flex items-start justify-between p-4rounded-t">
                      <button type="button" onclick=${this.closeDialog.bind(this)} class="text-black bg-transparent border border-solid border-black hover:cursor-pointer hover:text-white hover:bg-black text-sm w-4 h-4 box-content p-2 ml-auto inline-flex justify-center items-center" data-modal-hide="defaultModal">
                          <svg class="w-5 h-5" aria-hidden="true" fill="none" viewBox="0 0 14 14">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                          </svg>
                          <span class="sr-only">Close modal</span>
                      </button>
                  </div>
                  <!-- Modal body -->
                  <div class="flex flex-col">
                    <span class="p-4">You are about to visit a website outside of medicinenet. Please familiarize yourself with this other website's Privacy Policy as it differs from ours.</span>
                    <div class="flex p-2"><button onclick=${this.openUrl.bind(this)} class="m-auto px-14 py-4 border-0 uppercase bg-sky-400 text-white hover:bg-black hover:text-white hover:cursor-pointer">continue</button></div>
                  </div>
              </div>
          </div>
      </div>
    `
    this.State = {
      View,
      Element: container,
      ui: {close: 'hidden'},
      data: {
        url: targetEl.href
      }
    }
    this.Update = Nanny(this.State)
  }

  setupListener() {
    let targetEl = this.querySelector('a')
    targetEl.addEventListener('click', (e) => {
      e.preventDefault()
      this.openDialog()
    })
  }
  openDialog() {
    this.Update(state => {
      state.ui.close = ''
      return state
    })
  }
  closeDialog() {
    this.Update(state => {
      state.ui.close = 'close'
      return state
    })
  }
  openUrl() {
    this.closeDialog()
    let url = this.State.data.url
    window.open(url, '_blank')
  }
  clickMask(e) {
    const dialog = this.querySelector('.dialog')
    if (!dialog.contains(e.target)) {
      this.closeDialog()
    }
  }
}