import Nanny from "nanny-state"

const spacePattern = /[\/]/g
const searchPattern = /[A-Za-z0-9-,\/\s]/g
const filterPattern = /[A-Za-z0-9-,\s]/g

export default class SearchBox extends HTMLElement {

  constructor() {
    super()
    this.initialized = false
  }

  connectedCallback() {
    if (!this.initialized) {
      this.init()
    }
  }

  async init() {
    // console.log('init search box')
    // console.log('search box init:', this.children, this.attributes, this.getAttribute('placeholder'))
    this.setupView()
    this.setupListener()
    this.initialized = true
  }

  setupView() {
    const Item = (state, item, index) => {
      let bg = state.data.selectedIndex == index?'bg-sky-400':''
      return state.HTML`<li role="option" tabindex="${index}" class=${`px-4 py-1 hover:bg-sky-400 cursor-pointer ${bg}`} @click=${this.onSelect.bind(this)}>${item.Name}</li>`
    }
    // const buttonIconEnable = this.getAttribute('button-icon-enabled')?'':'icon-disabled' + ' sc-searchbox-button icon-search'
    const inputField = (state, props) => {
      return state.HTML`
      <div class="relative flex w-full">
      <input class="w-full h-9 overflow-visible box-border border-2 border-sky-400 rounded-l-md placeholder:italic placeholder:text-slate-400 text-sm pl-2 py-4 bg-transparent focus:outline-none focus:ring-0"
              name="searchText"
              oninput=${this.changeQuery.bind(this)}
              value=${`${state.data.searchString}`}
              type="search"
              autocomplete="off"
              placeholder="${this.getAttribute('placeholder')}"
              disabled="${this.getAttribute('disabled')}"
              @focus=${this.onFocus.bind(this)}
              @blur=${this.onBlur.bind(this)}
              
              onkeyup=${this.onkeyup.bind(this)}
            >
            <button class=${`${state.data.searchString?'':'hidden'} absolute w-6 h-full right-2 items-center text-black hover:text-sky-400 dark:placeholder:text-neutral-200`} onclick=${this.clearSearch.bind(this)}>
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
          </div>
      `
    }

    const View = state => state.HTML`
      <div class="p-1">
        <div class="flex flex-col gap-y-1" @submit=${this.onFormSubmit.bind(this)}>
          <div class="flex">
            ${inputField(state,{})}
            <button class=${`${state.props.showSearchButton?'':'hidden'} px-4 border border-sky-400 rounded-r-md bg-sky-400 text-white`} type="submit">${state.props.searchButtonText}</button>
          </div>
          </div>
          ${state.data.listItems.length ?
            state.HTML`<div class="py-2 bg-neutral-200 rounded-md">
              <ul name="searchResult" class="py-1">
                ${state.data.listItems.length ?
                  state.HTML`${state.data.listItems.map((item, index) => Item(state, item, index))}`: ''}
              </ul>
            </div>`:''
          }
      </div>
    `
    this.State = {
      View,
      Element: this,
      props: {
        hostname: window.location.hostname.replace('comments','www'),
        showSearchButton: (/true/i).test(this.getAttribute('show-search-button')),
        searchButtonText: this.getAttribute('search-button-text'),
        searchApi: this.getAttribute('search-api'),
        submitApi: this.getAttribute('submit-api')
      },
      ui: {
      },
      data: {
        searchString:'',
        listItems:[],
        selectedIndex:-1
      }
    }
    this.Update = Nanny(this.State)
  }

  async updateData(data) {
    this.Update(state => {
      state.data = data
      return state
    })
  }

  setupListener() {
    document.addEventListener("click", e => {
      this.onClick()
      // console.log('click:', this.State, this.State.data.searchString, this.State.data.selectedIndex)
      if (!this.contains(e.target)) {
        this.clearSearch()
      }
    })
    const searchInput = this.querySelector('[name="searchText')
    this.addEventListener('focus', e => {
      // console.log('received focus')
      searchInput.focus()
    })
    this.addEventListener('keyup', e => {
      // console.log('keydown:', event.target.value)
      if (e.key === "ArrowDown") {
        this.moveDown()
      } else if (e.key == "ArrowUp") {
        this.moveUp()
      } else if (e.key == "Enter") {
        if (this.State.data.selectedIndex != -1) {

          let searchStr = this.State.data.listItems[this.State.data.selectedIndex]
          // console.log('searchStr:', searchStr)
          this.Update(state => {
            state.data.searchString = searchStr
            return state
          })
          this.forwardSearch(searchStr.Name)
        } else if (this.State.data.searchString) {
          this.forwardSearch(this.State.data.searchString)
        }
        // console.log('enter:', this.State, this.State.data.searchString, this.State.data.selectedIndex)
      } else {
        let search = event.target.value
        this.doSearch(search)
      }
    })
  }

  onClick() {
    // console.log('on click:', this.State, this.State.data.searchString, this.State.data.selectedIndex)
  }

  clearSearch() {
    // console.log('clear search')
    this.querySelector('input').value = ''
    this.updateData({
      searchString: '',
      listItems: [],
      selectedIndex: -1
    })
  }

  onFocus(e) {
    // console.log('focus')
    const search = e.target.value
    if (search) {
      this.doSearch(search)
    }
  }

  onBlur() {
    // console.log('blur')
  }

  async moveDown() {
    if (this.State.data.listItems.length > 0) {
      let currentIndex = this.State.data.selectedIndex
      // console.log('move Down:', currentIndex, this.State.data.listItems.length)
      if (currentIndex < this.State.data.listItems.length - 1) {
        // console.log('move down select:', currentIndex + 1, this.State.data.selectedIndex)
        this.Update(state => {
          state.data = {...state.data, selectedIndex: currentIndex + 1}
          // state.data.selectedIndex = currentIndex + 1
          return state
        })
      }
    }
  }

  async moveUp() {
    if (this.State.data.listItems.length > 0) {
      let currentIndex = this.State.data.selectedIndex
      // console.log('move up:', currentIndex, this.State.data.listItems.length)
      if (currentIndex > 0) {
        this.Update(state => {
          state.data = {...state.data, selectedIndex: currentIndex - 1}
          // state.data.selectedIndex = currentIndex - 1
          return state
        })
      }
    }
  }

  async onkeyup(e) {
    const search = e.target.value
    // console.log('onkeyup:', event.target)
    if (search) {
      // this.Update(state => {
      //   state.data.searchString = search
      //   return state
      // })
      // this.doSearch(search)
    }
  }

  async onSearch(e) {
    // console.log('onSearch')
    this.updateData({
      searchString: '',
      listItems: [],
      selectedIndex: -1
    })
  }

  async doSearch(query) {
    // console.log('doSearch')
    let searchUrl = new URL(`https://${this.State.props.hostname + '/' + this.State.props.searchApi + '/' + query}`)
    const response = await fetch(searchUrl)
    const data = await response.json()
    let transformedData = data.map((d) => `<div class="item">${d}</div`)
    // this.updateData({
    //   searchString: query,
    //   listItems: data,
    //   selectedIndex: -1
    // })
    this.Update(state => {
      state.data = {...state.data, ...{searchString: query, listItems: query?data:[], selectedIndex: -1}}
      // state.data.searchString = query
      // state.data.listItems = query?data:[]
      // if (data && data.length) {
        // state.data.selectedIndex = -1
      // }
      return state
    })
  }

  async onEnter(e) {
    // console.log('on enter')
  }

  async onFormSubmit(e) {
    e.preventDefault()
    // console.log('submit:', e.target.searchText.value)
    // e.preventDefault()
    // let searchText = e.target.searchText.value
    // if (searchText) {
    //   this.forwardSearch(searchText)
    // }
  }

  async onSelect(e) {
    // console.log('on select')
    let searchText = e.target.textContent
    if (searchText) {
      this.forwardSearch(searchText)
    }
  }

  async changeQuery(e) {
    // console.log('change query:', e.target.value)
    // this.Update(state => {
    //   state.data.searchString = e.target.value
    //   if (e.target.value == '') {
    //     state.data.listItems = []
    //     state.data.selectedIndex = -1
    //   }
    //   return state
    // })
  }
  
  forwardSearch(searchText) {
    let searchStr = searchText.match(searchPattern).join('').replace(spacePattern, ' ')
    searchStr = searchStr.replace(/ +(?= )/g,'').trim()
    let searchUrl = `https://${this.State.props.hostname}${this.State.props.submitApi}/${searchStr}`
    window.location = searchUrl
  }
}