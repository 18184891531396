import Nanny from "nanny-state"

export default class NewsletterButton extends HTMLElement {

  constructor() {
    super()
    this.initialized = false
  }

  connectedCallback() {
    if (!this.initialized) {
      this.init()
    }
  }

  async init() {
    this.hostname = window.location.hostname 
    this.setupView()
    this.setupListener()
  }

  setupView() {
    const iconOnly = this.getAttribute('icon-only')
    console.log('icon only: ' + iconOnly)
    const envelopSvg = ``
    const FullButton = (state, props) => {
      return state.HTML
    `
    <!-- Modal toggle -->
    <button onclick=${this.openDialog.bind(this)} class="subscribe-icon flex items-center px-1.5 py-1 lg:border rounded-md box-border border-sky-400 text-sky-400 hover:bg-sky-400 hover:text-white cursor-pointer lg:group-[.open-search]:hidden">
          <svg class="icon w-5 h-5 fill-current" viewBox="0 0 1691 1407" width="30" height="30"><use xlink:href="#envelop-icon"/></svg>
          <span class="pl-2">Subscribe</span>
        </button>`
    }
    const IconButton = (state, props) => {
      return state.HTML
      `
      <div onclick=${this.openDialog.bind(this)} class="hover:text-sky-400 cursor-pointer">
        <svg class="icon w-5 h-5 fill-current" viewBox="0 0 1691 1407" width="30" height="30"><use xlink:href="#envelop-icon"/></svg>
      </div>`
    }
    const View = state => state.HTML`
    <svg hidden><symbol id="envelop-icon" viewBox="0 0 1691 1407" height="1407px" width="1691px"><g id="packet"><path d="M1691,165.177C1691,73.952,1617.048,0,1525.823,0H165.177C73.952,0,0,73.952,0,165.177v1076.646   C0,1333.048,73.952,1407,165.177,1407h1360.646c91.225,0,165.177-73.952,165.177-165.177V165.177z M166.062,132h1361.057   c18.216,0,32.881,14.528,32.881,32.746v1.433L869.916,856.337c-8.417,8.417-18.208,9.675-23.318,9.675   c-5.11,0-14.934-1.258-23.353-9.675L133,166.085v-1.339C133,146.528,147.846,132,166.062,132z M1527.119,1275H166.062   c-18.216,0-33.062-15.084-33.062-33.301V352.961l596.826,596.816c31.198,31.197,72.684,48.376,116.803,48.376   c44.125-0.003,85.528-17.186,116.724-48.382L1560,353.054v888.645C1560,1259.916,1545.335,1275,1527.119,1275z"/></g></symbol></svg>
      ${iconOnly !== null? IconButton(state) : FullButton(state)}
      <!-- Main modal -->
      <div tabindex="-1" onclick=${this.clickMask.bind(this)} aria-hidden="true" class=${`${state.ui.close?'hidden':''} flex fixed z-10 top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-opacity-70 bg-black box-border`}>
          <div class="relative self-center h-full w-full max-w-2xl max-h-full mx-auto py-16 box-border">
              <!-- Modal content -->
              <div class="dialog relative bg-white rounded-lg shadow h-full box-border flex flex-col">
                  <!-- Modal header -->
                  <div class="flex items-start justify-between p-4rounded-t">
                      <button type="button" onclick=${this.closeDialog.bind(this)} class="text-gray-400 bg-transparent hover:text-sky-400 text-sm w-5 h-5 box-content p-4 ml-auto inline-flex justify-center items-center" data-modal-hide="defaultModal">
                          <svg class="w-5 h-5" aria-hidden="true" fill="none" viewBox="0 0 14 14">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                          </svg>
                          <span class="sr-only">Close modal</span>
                      </button>
                  </div>
                  <!-- Modal body -->
                  <iframe src=${state.data.iframeUrl} class="h-full w-full mx-auto p-4 box-border"></iframe>
              </div>
          </div>
      </div>
    `
    this.State = {
      View,
      Element: this,
      ui: {close: 'hidden'},
      data: {iframeUrl: ''}
    }
    this.Update = Nanny(this.State)
  }

  setupListener() {
  }

  openDialog() {
    this.Update(state => {
      state.ui.close = ''
      state.data.iframeUrl = `https://${this.hostname}/script/main/newsletter-signupmodal_v2.asp`
      return state
    })
  }
  closeDialog() {
    this.Update(state => {
      state.ui.close = 'close'
      return state
    })
  }
  clickMask(e) {
    const dialog = this.querySelector('.dialog')
    if (!dialog.contains(e.target)) {
      this.closeDialog()
    }
  }
  
}